export function nameProductsType(category: string) {
  switch (category) {
    case 'NISSAN':
      return 'Nissan Vehicles'
    case 'UD_TRUCKS':
      return 'UD Trucks '
    case 'UNICARRIER':
      return 'Unicarrier'
    case 'EICHER':
      return 'Eicher'
    case 'MAC_POWER':
      return 'Macpower Battrey'
    default:
      return ''
  }
}
